@import 'src/style/default';

.vacancy__frame {
  display: flex;
  align-items: center;

  padding: 0 20px;

  background-image: url('../../../../assets/about_img/lines.svg');
  background-position: right;
  background-position-y: 0%;
  background-size: 60%;
  background-repeat: no-repeat;

  .vacancy__container {
    max-width: 1420px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 40px;

    margin: 0 auto;

    h1 {
      font-weight: 400;
    }

    .vacancy__info-container {
      display: flex;
      gap: 40px;

      span {
        font-size: 20px;
        font-weight: 400;
      }
    }

    .vacancy__condition-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .vacancy__condition-title {
        font-size: clamp(22px, 2.2vw, 28px);
        font-weight: 400;
      }

      .vacancy__list-container {
        li {
          font-size: 16px;
          margin-left: 20px;

          .vacancy__list-item {
            font-size: clamp(16px, 2.2vw, 20px);
            font-weight: 400;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint_md) {
  .vacancy__frame {
    background-image: none;
  }
}
