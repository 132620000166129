@import "src/style/default";

.dashboard__frame {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 20px;

  .dashboard__container {
    max-width: 1420px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    .dashboard__left-container {
      display: flex;
      flex-direction: column;
      gap: 50px;

      .dashboard__text-container {
        h1 {
          font-weight: 400;
          color: #141419;
        }

        h1:nth-child(2) {
          font-weight: 700;
        }

        h1:nth-child(3) {
          font-weight: 700;

          span {
            color: #FF7A00;
          }
        }
      }
    }
  }
}

@media (min-width: $breakpoint_2xl) {
  .content-circle {
    width: 400px;
    height: 400px;
  }
}

@media (max-width: $breakpoint_xl) {
  .content-circle {
    width: 350px;
    height: 350px;
  }
}

@media (max-width: $breakpoint_lg) {
  .content-circle {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: $breakpoint_md) {
  .content-circle {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: $breakpoint_s) {
  .content-circle {
    width: 180px;
    height: 180px;
  }
}

@media (max-width: $breakpoint_xs) {
  .content-circle {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: $breakpoint_xs - 1) {
  .content-circle {
    width: 112px;
    height: 112px;
  }
}