@import 'src/style/default.scss';

.domains-sidebar__container {
  max-width: 350px;
  width: 100%;
  padding: 40px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  background: #eeeeee;

  .domains-sidebar__link {
    padding: 10px 10px 10px 0;

    cursor: pointer;

    p {
      font-size: 20px;
      font-weight: 400;
      color: #141419;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      p {
        font-weight: 700;
      }
    }
  }

  .selected {
    border-left: 3px solid #ff7a00;

    p {
      font-weight: 700;
      margin-left: 20px;
      transition: all 0.3s ease-in-out;
    }
  }
}

@media (max-width: $breakpoint_md) {
  .domains-sidebar__container {
    max-width: 100%;
  }
}
