@import 'src/style/default';

.dashboard__card {
  max-width: 400px;
  height: 300px;

  background: #eeeeee;

  padding: 40px 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 28px;
      font-weight: 600;
      color: #141419;
    }

    span {
      font-size: 40px;
      font-weight: 900;
      color: #ff7a00;
    }
  }

  p {
    width: 70%;
  }
}

@media (max-width: $breakpoint_s) {
  .dashboard__card {
    max-width: 100%;
  }
}
