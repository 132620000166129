.GamarButton {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 30px;

  background: #141419;
  border: none;

  transition: all 0.3s;

  outline: none;
  cursor: pointer;

  font-weight: 400;
  font-size: 20px;
  color: #f8f8f8;

  &:hover {
    background: #ff7a00;
  }

  &:disabled {
    opacity: 0.5;
    background: #141419;
  }
}
