@import "src/style/default";

.dashboard-technologies__frame {
  display: flex;
  align-items: center;
  padding: 0 20px;

  .dashboard-technologies__container {
    max-width: 1420px;
    width: 100%;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 40px;

    .dashboard-technologies__title {
      font-weight: 400;
      font-size: 80px;
      color: #141419;
    }

    .dashboard-technologies__content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;

      .card-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 500px;

        padding: 40px 20px;

        background: #EEEEEE;

        p {
          font-weight: 600;
          font-size: 28px;
          height: 50px;

          span {
            color: #FF7A00;
            font-size: 40px;
            font-weight: 900;
          }
        }

        ul {
          position: absolute;

          bottom: 5%;
          margin-left: 20px;

          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px 30px;

          li {
            list-style: circle;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint_xl) {
  .dashboard-technologies__frame .dashboard-technologies__container .dashboard-technologies__content {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: $breakpoint_lg) {
  .dashboard-technologies__frame .dashboard-technologies__container .dashboard-technologies__content {
    grid-template-columns: repeat(2, 1fr);

    .card-container {
      img {
        align-self: center;
        width: 300px;
        height: 300px;
      }
    }
  }
}

@media (max-width: $breakpoint_s) {
  .dashboard-technologies__frame .dashboard-technologies__container .dashboard-technologies__content {
    grid-template-columns: repeat(1, 1fr);

    .card-container {
      img {
        align-self: center;
        width: 300px;
        height: 300px;
      }
    }
  }
}