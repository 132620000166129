@import 'src/style/default';

.footer-frame {
  display: flex;
  align-items: center;
  padding: 50px 20px;
  background: #110b11;

  .footer-content {
    max-width: 1420px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 40px;

    h2 {
      font-weight: 700;
      font-size: 40px;

      color: #f8f8f8;
    }

    .footer-form-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .footer-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 700px;
        width: 100%;

        .footer-form-row {
          display: flex;
          gap: 20px;
          width: 100%;

          .input-container {
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 100%;
            position: relative;

            label {
              font-size: 16px;
              line-height: 22px;
              color: #f8f8f8;
            }

            input {
              background: #110b11;
              height: 50px;
              padding: 0 20px;
              font-size: 16px;
              line-height: 22px;
              border: 1px solid #f8f8f8;
              color: #f8f8f8;
            }

            textarea {
              background: #110b11;
              height: 150px;
              padding: 20px;
              font-size: 16px;
              line-height: 22px;
              border: 1px solid #f8f8f8;
              color: #f8f8f8;
              resize: none;
            }
          }
        }
      }

      ul {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-self: end;

        a {
          list-style: none;
          font-size: 16px;
          line-height: 22px;
          color: #f8f8f8;
          border-bottom: 1px solid transparent;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          text-decoration: none;

          &:hover {
            transition: all 0.3s ease-in-out;
            border-bottom: 1px solid #ff7a00;
          }
        }
      }
    }

    svg {
      width: 100%;
      max-width: 900px;
      height: auto;
      align-self: end;

      path {
        fill: #f8f8f8;
      }
    }
  }
}

@media (max-width: $breakpoint_xl) {
  .footer-frame .footer-content .footer-form-container {
    align-items: flex-start;
    flex-direction: column;
    gap: 40px;

    ul {
      align-self: start;
    }
  }
}

@media (max-width: $breakpoint_md) {
  .footer-frame .footer-content .footer-form-container .footer-form .footer-form-row {
    flex-direction: column;
    gap: 20px;
  }
}
