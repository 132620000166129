@import 'src/style/default';

.header__frame {
  width: 100%;

  height: 45px;
  padding: 20px 0px;

  position: fixed;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 2;

  background: #ffff;

  .header__content {
    max-width: 1420px;
    width: 100%;

    height: 45px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    margin: 0 auto;

    .header__logo {
      cursor: pointer;
    }

    .header__nav-links {
      display: flex;
      flex-direction: row;
      gap: 40px;

      .link {
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
        padding-bottom: 2px;
        cursor: pointer;

        color: #141419;

        border-bottom: 1px solid transparent;
        transition: all 0.3s ease-in-out;

        &:hover {
          transition: all 0.3s ease-in-out;
          border-bottom: 1px solid #ff7a00;
        }
      }
    }
  }
}

@media (max-width: $breakpoint_md) {
  .header__frame .header__content {
    .header__nav-links {
      display: none;
    }

    button {
      display: none;
    }
  }
}
