.header__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .list-item {
    width: 100%;
    padding: 10px 0;

    a {
      color: #141419;
      text-decoration: none;
      cursor: pointer;
      font-size: 24px;
      font-weight: 400;
      transition: all 0.3s ease-in-out;
    }

    .btn-text {
      font-size: 32px;
    }

    &:hover {
      a {
        color: #ff7a00;
      }
    }
  }
}

.btn {
  p {
    font-size: 32px;
  }
}
