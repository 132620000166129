@import 'src/style/default';

.about-us-section__frame {
  display: flex;
  align-items: flex-start;
  padding: 0 20px;
  height: 400px;

  background-position: right;
  background-position-y: 0%;
  background-size: 60%;
  background-repeat: no-repeat;

  .about-us-section__content {
    max-width: 1420px;
    width: 100%;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 40px;

    .about-us-section__title {
      font-weight: 400;
      color: #141419;
    }
  }

  .about-us-section__text-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      font-weight: 400;
      color: #141419;
      font-size: clamp(16px, 2.2vw, 20px);
    }
  }
}

@media (max-width: $breakpoint_md) {
  .about-us-section__frame {
    background-position-y: 5%;
    background-position-x: 400%;
    background-size: 90%;
    height: auto;

    .about-us-section__text-container {
      width: 80%;
    }
  }
}
