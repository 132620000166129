@import 'src/style/default';

.contact-modal-frame {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;

  .contact-modal-content {
    max-width: 900px;
    width: 100%;

    padding: 40px;

    display: flex;
    flex-direction: column;
    gap: 40px;

    background: #f8f8f8;

    .contact-modal__header {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .header-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        p {
          font-weight: 700;
          font-size: 32px;

          display: flex;
          align-items: center;
          color: #141419;
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        color: #141419;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;

      .form-row {
        display: flex;
        gap: 20px;
        width: 100%;

        .input-container {
          display: flex;
          flex-direction: column;
          gap: 5px;
          width: 100%;
          position: relative;

          label {
            font-size: 16px;
            line-height: 22px;
            color: #141419;
          }

          input {
            background: #f8f8f8;
            height: 50px;
            padding: 0 20px;
            font-size: 16px;
            line-height: 22px;
            border: 1px solid #141419;
            color: #141419;
          }

          textarea {
            background: #f8f8f8;
            height: 150px;
            padding: 20px;
            font-size: 16px;
            line-height: 22px;
            border: 1px solid #141419;
            color: #141419;
            resize: none;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint_md) {
  .contact-modal-frame .contact-modal-content .form .form-row {
    flex-direction: column;
    gap: 20px;
  }
}
