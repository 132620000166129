@import 'src/style/default';

.dashboard-domains__frame {
  display: flex;
  align-items: center;
  padding: 0 20px;

  .dashboard-domains__container {
    max-width: 1420px;
    width: 100%;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 40px;

    .dashboard-domains__title {
      font-weight: 400;
      font-size: 80px;
      color: #141419;
    }

    .dashboard-domains__content {
      display: flex;
      justify-content: space-between;

      .dashboard-domains__link-container {
        width: 100%;
        max-width: 800px;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 20px;

        .link {
          display: flex;
          align-items: center;
          gap: 15px;
          height: 50px;
          width: auto;

          padding: 10px 10px 0;

          a {
            font-size: 20px;
            font-weight: 400;

            text-decoration: none;
            color: #141419;

            transition: all 0.3s ease-in-out;
          }

          .arrow-link {
            opacity: 0;
            width: 24px;
            height: 24px;

            transition: all 0.3s ease-in-out;
          }

          &:hover {
            a {
              font-weight: 700;
            }

            .arrow-link {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint_lg) {
  .domains-figure {
    width: 280px;
    height: 170px;
  }

  .dashboard-domains__frame
    .dashboard-domains__container
    .dashboard-domains__content
    .dashboard-domains__link-container {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: $breakpoint_md) {
  .dashboard-domains__frame .dashboard-domains__container .dashboard-domains__content {
    flex-direction: column-reverse;
    gap: 20px;

    .dashboard-domains__link-container {
      max-width: 100%;
    }
  }
}
