@import 'src/style/default';

.dashboard__cards-frame {
  display: flex;
  align-items: center;

  padding: 0 20px;

  .dashboard__cards-container {
    max-width: 1420px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 40px;

    margin: 0 auto;

    .dashboard__cards-title {
      font-weight: 400;
      font-size: 80px;
      color: #141419;
    }

    .dashboard__cards-content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;

      #card-01 {
        grid-column: 3;
        grid-row: 1;
      }

      #card-02 {
        grid-column: 1;
        grid-row: 2;
      }

      #card-03 {
        grid-column: 4;
        grid-row: 2;
      }

      #card-04 {
        grid-column: 2;
        grid-row: 3;
      }

      #card-05 {
        grid-column: 3;
        grid-row: 3;
      }
    }
  }
}

@media (max-width: $breakpoint_xl) {
  .dashboard__cards-frame .dashboard__cards-container .dashboard__cards-content {
    grid-template-columns: repeat(3, 1fr);

    #card-01 {
      grid-column: 1;
      grid-row: 1;
    }

    #card-02 {
      grid-column: 3;
      grid-row: 1;
    }

    #card-03 {
      grid-column: 2;
      grid-row: 2;
    }

    #card-04 {
      grid-column: 1;
      grid-row: 3;
    }

    #card-05 {
      grid-column: 3;
      grid-row: 3;
    }
  }
}

@media (max-width: $breakpoint_md) {
  .dashboard__cards-frame .dashboard__cards-container .dashboard__cards-content {
    grid-template-columns: repeat(2, 1fr);

    #card-01 {
      grid-column: auto;
      grid-row: auto;
    }

    #card-02 {
      grid-column: auto;
      grid-row: auto;
    }

    #card-03 {
      grid-column: auto;
      grid-row: auto;
    }

    #card-04 {
      grid-column: auto;
      grid-row: auto;
    }

    #card-05 {
      grid-column: auto;
      grid-row: auto;
    }
  }
}

@media (max-width: $breakpoint_s) {
  .dashboard__cards-frame .dashboard__cards-container .dashboard__cards-content {
    grid-template-columns: repeat(1, 1fr);
  }
}
