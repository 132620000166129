@import 'src/style/default.scss';

.domain-roles__frame {
  display: flex;
  align-items: center;

  padding: 0 20px;

  .domain-roles__container {
    max-width: 1420px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 40px;

    margin: 0 auto;

    h2 {
      font-weight: 400;
      font-size: 80px;
      color: #141419;
    }

    .domain-roles__content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      gap: 40px;

      .role-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        max-width: 800px;

        .role-label {
          font-size: 40px;
          font-weight: 400;
        }

        .role-content {
          display: flex;
          flex-direction: column;

          gap: 10px;

          .role-item {
            font-size: 16px;
            font-weight: 400;
            margin-left: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint_md) {
  .domain-roles__frame .domain-roles__container .domain-roles__content .role-container .role-label {
    align-self: end;
    font-size: 24px;
  }
}
