@import "src/style/default";

// For screens larger than or equal to 1536px
@media (min-width: $breakpoint_2xl) {
  h1 {
    font-size: 80px;
  }
}

// For screens between 1280px and 1535px
@media (min-width: $breakpoint_xl) and (max-width: $breakpoint_2xl - 1) {
  h1 {
    font-size: 70px; // Adjusted for gradual change
  }
}

// For screens between 1024px and 1279px
@media (min-width: $breakpoint_lg) and (max-width: $breakpoint_xl - 1) {
  h1 {
    font-size: 60px;
  }
}

// For screens between 768px and 1023px
@media (min-width: $breakpoint_md) and (max-width: $breakpoint_lg - 1) {
  h1 {
    font-size: 50px;
  }
}

// For screens between 640px and 767px
@media (min-width: $breakpoint_s) and (max-width: $breakpoint_md - 1) {
  h1 {
    font-size: 40px;
  }
}

// For screens between 480px and 639px
@media (min-width: $breakpoint_xs) and (max-width: $breakpoint_s - 1) {
  h1 {
    font-size: 38px; // Slightly adjust to fit the scale
  }
}

// For screens smaller than 480px
@media (max-width: $breakpoint_xs - 1) {
  h1 {
    font-size: 36px;
  }
}
