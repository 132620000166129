.successful-modal__frame {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;

  .successful-modal__content {
    max-width: 620px;
    width: 100%;

    padding: 40px;

    display: flex;
    flex-direction: column;
    gap: 40px;

    background: #f8f8f8;

    .successful-modal__header {
      display: flex;
      flex-direction: column;
      gap: 20px;

      width: 100%;

      .header-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        p {
          font-weight: 700;
          font-size: 32px;

          display: flex;
          align-items: center;
          color: #141419;
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        color: #141419;
      }
    }
  }

  img {
    width: 95px;
    height: 95px;
    align-self: center;
  }
}
