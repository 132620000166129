@import 'src/style/default';

.career-content__frame {
  display: flex;
  align-items: center;

  padding: 0 20px;

  .career-content__container {
    max-width: 1420px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 40px;

    margin: 0 auto;

    .career-content__cards-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
    }
  }
}

@media (max-width: $breakpoint_xl) {
  .career-content__frame .career-content__container .career-content__cards-container {
    grid-template-columns: repeat(3, 1fr);

    #card-01 {
      grid-column: 1;
      grid-row: 1;
    }

    #card-02 {
      grid-column: 3;
      grid-row: 1;
    }

    #card-03 {
      grid-column: 2;
      grid-row: 2;
    }

    #card-04 {
      grid-column: 1;
      grid-row: 3;
    }

    #card-05 {
      grid-column: 3;
      grid-row: 3;
    }
  }
}

@media (max-width: $breakpoint_md) {
  .career-content__frame .career-content__container .career-content__cards-container {
    grid-template-columns: repeat(2, 1fr);

    #card-01 {
      grid-column: auto;
      grid-row: auto;
    }

    #card-02 {
      grid-column: auto;
      grid-row: auto;
    }

    #card-03 {
      grid-column: auto;
      grid-row: auto;
    }

    #card-04 {
      grid-column: auto;
      grid-row: auto;
    }

    #card-05 {
      grid-column: auto;
      grid-row: auto;
    }
  }
}

@media (max-width: $breakpoint_s) {
  .career-content__frame .career-content__container .career-content__cards-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
