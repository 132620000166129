@import 'src/style/default.scss';

.domains-numbers__frame {
  display: flex;
  align-items: center;

  padding: 0 20px;

  .domain-numbers__container {
    max-width: 1420px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 40px;

    margin: 0 auto;

    h2 {
      font-weight: 400;
      font-size: 80px;
      color: #141419;
    }

    .domain-numbers__content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px 0;

      .number-card {
        width: 240px;
        height: 205px;

        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;

        background: #eeeeee;

        span {
          font-size: 40px;
          font-weight: 900;
          color: #ff7a00;
          align-self: end;
        }

        p {
          font-size: 28px;
          font-weight: 600;
        }
      }

      #number-card-1 {
        grid-column: 1;
        grid-row: 2;
      }

      #number-card-2 {
        grid-column: 2;
        grid-row: 1;
        justify-self: center;
      }

      #number-card-3 {
        grid-column: 3;
        grid-row: 1;
        justify-self: center;
      }

      #number-card-4 {
        grid-column: 4;
        grid-row: 2;
        justify-self: end;
      }
    }
  }
}

@media (max-width: $breakpoint_xl) {
  .domains-numbers__frame .domain-numbers__container .domain-numbers__content {
    grid-template-columns: repeat(2, 1fr);

    #number-card-1 {
      grid-column: 1;
      grid-row: 2;
    }

    #number-card-2 {
      grid-column: 1;
      grid-row: 1;
      justify-self: center;
    }

    #number-card-3 {
      grid-column: 2;
      grid-row: 1;
      justify-self: center;
    }

    #number-card-4 {
      grid-column: 2;
      grid-row: 2;
      justify-self: end;
    }
  }
}

@media (max-width: $breakpoint_xl) {
  .domains-numbers__frame .domain-numbers__container .domain-numbers__content {
    grid-template-columns: repeat(1, 1fr);

    #number-card-1 {
      grid-column: 1;
      grid-row: 1;
      justify-self: end;
    }

    #number-card-2 {
      grid-column: 1;
      grid-row: 2;
      justify-self: start;
    }

    #number-card-3 {
      grid-column: 1;
      grid-row: 3;
      justify-self: end;
    }

    #number-card-4 {
      grid-column: 1;
      grid-row: 4;
      justify-self: start;
    }
  }
}
