@import 'src/style/default.scss';

.domains__main-container {
  display: flex;
  flex-direction: column;
  gap: 100px;

  .domains-fab {
    position: fixed;
    bottom: 40px;
    right: 40px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    z-index: 2;
    width: 52px;
    height: 52px;

    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #141419;

    svg {
      fill: #ffffff;
      stroke: #ffffff;
    }

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }
}

@media (max-width: $breakpoint_md) {
  .domains__main-container {
    gap: 50px;
  }
}
