@import 'src/style/default';

.career-card__container {
  background: #eeeeee;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 20px;
  max-width: 400px;
  position: relative;
  transition: all 0.3s ease-in-out;

  .career-card__experience {
    font-weight: 900;
    font-size: 40px;
    color: #ff7a00;
    align-self: flex-end;
  }

  .career-card__position {
    font-weight: 600;
    font-size: 28px;
    color: #141419;
  }

  .career-card__additional-info-container {
    display: flex;
    flex-direction: row;
    gap: 40px;

    span {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .arrow-link {
    width: 64px;
    height: 64px;
    bottom: 20px;
    right: 20px;
    position: absolute;
    opacity: 0;
    transform: translateY(40px);
    transition: all 0.3s ease-in-out;

    path {
      fill: #ffffff;
      stroke: #ffffff;
    }
  }

  &:hover {
    background: #141419;

    .career-card__position {
      color: #ffffff;
    }

    .career-card__position {
      color: #ffffff;
    }

    .career-card__additional-info-container {
      span {
        color: #ffffff;
      }
    }

    .arrow-link {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

#card-01 {
  grid-column: 2;
  grid-row: 1;
}

#card-02 {
  grid-column: 1;
  grid-row: 2;
}

#card-03 {
  grid-column: 3;
  grid-row: 2;
}

#card-04 {
  grid-column: 2;
  grid-row: 3;
}

#card-05 {
  grid-column: 4;
  grid-row: 3;
}

@media (max-width: $breakpoint_xl) {
  #card-01 {
    grid-column: 1;
    grid-row: 1;
  }

  #card-02 {
    grid-column: 3;
    grid-row: 1;
  }

  #card-03 {
    grid-column: 2;
    grid-row: 2;
  }

  #card-04 {
    grid-column: 1;
    grid-row: 3;
  }

  #card-05 {
    grid-column: 3;
    grid-row: 3;
  }
}

@media (max-width: $breakpoint_md) {
  .career-card__container {
    max-width: 100%;
  }

  #card-01 {
    grid-column: auto;
    grid-row: auto;
  }

  #card-02 {
    grid-column: auto;
    grid-row: auto;
  }

  #card-03 {
    grid-column: auto;
    grid-row: auto;
  }

  #card-04 {
    grid-column: auto;
    grid-row: auto;
  }

  #card-05 {
    grid-column: auto;
    grid-row: auto;
  }
}
