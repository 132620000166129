.career__main-container {
  display: flex;
  flex-direction: column;
  gap: 150px;

  position: relative;

  scroll-behavior: smooth;

  .career-content__frame {
    margin-top: 150px;
  }

  .career-fab {
    position: fixed;
    bottom: 40px;
    right: 40px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    z-index: 2;
    width: 52px;
    height: 52px;

    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #141419;

    svg {
      fill: #ffffff;
      stroke: #ffffff;
    }

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }
}
