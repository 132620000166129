@import 'src/style/default';

.main-container {
  display: flex;
  flex-direction: column;
  gap: 150px;

  position: relative;

  scroll-behavior: smooth;

  .content-frame {
    display: flex;
    align-items: center;

    padding: 0 20px;
    margin-top: 150px;

    .content-container {
      max-width: 1420px;
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 80px;

      margin: 0 auto;

      h1 {
        font-weight: 400;
      }

      .additional-content-container {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
    }
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 20px;

  ul {
    margin-left: 20px;

    li {
      font-weight: 400;
      font-size: clamp(14px, 2.2vw, 20px);
    }
  }
}

.section-title {
  font-size: clamp(20px, 2.2vw, 28px);
  font-weight: 400;
}

p {
  font-weight: 400;
  font-size: clamp(14px, 2.2vw, 20px);
}

.link {
  color: #ff7a00;
  text-decoration: underline;
  cursor: pointer;
}

.italic-text {
  font-style: italic;
  font-weight: 300;
  color: #1f1f1f;
}

b {
  color: #ff7a00;
}

.colored-item {
  color: #ff7a00;

  p {
    color: #1f1f1f;
  }
}

@media (max-width: $breakpoint_md) {
  .main-container {
    gap: 50px;
  }
}
