@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sniglet:wght@400;800&display=swap');

body {
  margin: 0;
  font-family: 'Urbanist', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.px-20 {
  padding: 0 20px;
}

* {
  font-family: 'Urbanist', sans-serif;
  margin: 0 0;
  padding: 0 0;

  scroll-behavior: smooth;
}

img {
  max-width: 100%;
}

.bold-text {
  font-weight: 700;
}

.orange-text {
  color: #ff7a00;
}
