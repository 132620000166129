@import 'src/style/default.scss';

.domains__frame {
  display: flex;
  align-items: center;
  padding: 0 20px;

  margin-top: 150px;

  .domains__content {
    max-width: 1420px;
    width: 100%;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 40px;

    .domains__title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-weight: 400;
        color: #141419;
      }

      img {
        display: none;
        transition: 0.5s all ease-in-out;

        &.active {
          rotate: 180deg;
        }
      }
    }

    .domains__info-section {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .domains__sidebar-row {
        display: flex;
        gap: 5%;

        .sidebar-container {
          max-width: 100%;
          width: 100%;
        }
      }

      .slide_2 {
        width: 100%;
        background-image: url('../../dashboard/dashboard_domains/domainsData/img/slides/slide-edtech-2.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 50%;
        height: 400px;
      }
    }
  }
}

@media (max-width: $breakpoint_md) {
  .domains__frame {
    margin-top: 110px;

    .domains__content {
      .domains__title {
        img {
          display: block;
        }
      }

      .domains__info-section {
        .domains__sidebar-row {
          flex-wrap: wrap;
          gap: 40px;
        }

        .slide_2 {
          padding: 0 20px;
          height: 240px;
          margin: 0 -20px;
          background-size: cover;
        }
      }
    }
  }
}
