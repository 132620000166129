@import 'src/style/default';

.about__main-container {
  display: flex;
  flex-direction: column;
  gap: 150px;

  .about-us-section__frame {
    margin-top: 150px;
  }

  .dashboard-fab {
    position: fixed;
    bottom: 40px;
    right: 40px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    z-index: 2;
    width: 52px;
    height: 52px;

    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #141419;

    svg {
      fill: #ffffff;
      stroke: #ffffff;
    }

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }
}

@media (max-width: $breakpoint_lg) {
  .about__main-container {
    gap: 100px;

    .about-us-section__frame {
      margin-top: 100px;
    }
  }
}
