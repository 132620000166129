@import 'src/style/default.scss';

.domains-info__frame {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .domains-info__image-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;

    background-size: cover;
    background-repeat: no-repeat;
    // background-position-x: 100%;

    .logo-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .logo {
        width: 82px;
        height: 82px;
      }

      p {
        font-family: 'Sniglet', sans-serif;
        font-size: 64px;
        font-weight: 400;
        color: #fff;
      }
    }
  }

  .domains-info__content {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .title-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .title {
        font-size: 40px;
        font-weight: 400;

        color: #000;
      }

      .logo-container {
        display: flex;
        align-items: center;
        gap: 20px;

        p {
          font-size: 20px;
        }
      }
    }

    .content {
      font-size: 16px;
    }

    .technologies-container {
      display: flex;
      gap: 20px;

      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}

@media (max-width: $breakpoint_md) {
  .domains-info__frame {
    .domains-info__image-container {
      padding: 0 20px;
      height: 240px;
      margin: 0 -20px;
      background-size: cover;

      .logo-container {
        display: none;
      }
    }

    .domains-info__content {
      .title-container {
        .title {
          font-size: 24px;
        }
      }
    }
  }
}
